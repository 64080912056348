.page-contact {
	width: 100%;
	background: var(--redGradient);
	padding: 50px 0 100px 0;
}

.contact {
	width: var(--widthContent);
	margin: 0 auto;
}

.contact p {
	width: 40%;
	color: white;
	letter-spacing: 1px;
}

.contact-methods {
	display: flex;
	justify-content: space-around;
	margin-top: 30px;
	margin-bottom: 70px;
}

.method-box {
	width: 33%;
	display: flex;
}

.method-box img {
	width: 50px;
	align-self: flex-start;
}

.method-box img svg {
	fill: white;
}

.method-box-content {
	flex-grow: 1;
	margin-left: 30px;
}

.method-name {
	margin-bottom: 10px;
	font-size: 24px;
	letter-spacing: 2px;
	color: white;
	text-shadow: var(--shadowText);
}

p.method-text {
	width: 100%;
	font-weight: 300;
	color: #ddd;
}

.contact-link img {
	display: block;
	width: 40px;
}

/* CONTACT CONTENT */

.contact-content {
	display: flex;
}

/* PHOTO CONTACT */

.contact-photo {
	width: 50%;
}

.contact-photo img {
	display: block;
	width: 95%;
	box-shadow: -2px -2px 40px 1px black;
}

/* CONTACT FORM */

.contact-form {
	width: 50%;
}

.contact-form h3 {
	text-align: center;
	font-size: 50px;
}

.contact-form form {
	text-align: center;
}

/* MEDIUM SCREEN */
@media (max-width: 1400px) {
	.contact {
		width: 90%;
	}
}
@media (max-width: 1200px) {
	.contact p {
		width: 100%;
	}
	.contact-methods {
		flex-wrap: wrap;
	}
	.method-box-content {
		width: 100%;
	}
	.method-box {
		width: 100%;
		margin-bottom: 20px;
	}

	.contact-content {
		flex-direction: column;
		align-items: center;
	}
	.contact-photo {
		width: 80%;
		margin-bottom: 40px;
	}
}
