.header {
	position: relative;
	width: 100%;
	height: 100%;
}

.header h1 {
	position: absolute;
	width: 100%;
	top: 40%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.2);
	padding: 10px 0;
	z-index: 5;
	color: white;
	letter-spacing: 3px;
	text-align: center;
	font-size: 86px;
	font-family: "Tangerine", cursive;
	text-shadow: 1px 1px 5px #000;
}

.heading-background {
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 300px;
	background-color: red;
	z-index: 6;
}

.header h1 span {
	display: block;
	padding: 2px 0;
	font-weight: normal;
}

/* ARROWS BUTTONS */
.slick-next,
.slick-prev {
	z-index: 5;
	right: 5%;
	top: 40%;
	cursor: pointer;
}

.slick-prev {
	left: 5%;
}

/* MOBILE SCREEN */

@media (max-width: 900px) {
	.header h1 {
		font-size: 64px;
	}
}

@media (max-width: 600px) {
	.header h1 {
		font-size: 54px;
	}
}

@media (max-width: 600px) {
	.header h1 {
		font-size: 44px;
	}
}
