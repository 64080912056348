#nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 150px;
	background: var(--blueGradient);
	z-index: 6;
}

#header {
	width: 100%;
	margin-top: 150px;
	margin-bottom: -4px;
}

#page {
	position: relative;
}

#footer {
	position: relative;
	padding-top: 20px;
	background-color: var(--blue);
}

/* MOBILE SCREEN */
@media (max-width: 900px) {
	#nav {
		height: 70px;
	}

	#header {
		margin-top: 70px;
	}
}
