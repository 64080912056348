:root {
	--orange: #ff9e5f;
	--orangeGradient: radial-gradient(
		circle at 50% 50%,
		#e68f55,
		#ea9156,
		#ed9358,
		#f19559,
		#f4985b,
		#f89a5c,
		#fb9c5e,
		#ff9e5f
	);
	--green: #82b334;
	--greenGradient: linear-gradient(
		90deg,
		#82b334 0%,
		#91c63c 50%,
		#82b334 100%
	);

	--red: #ff8b94;
	--redDark: #c95b66;
	--redTransparent: #c95b663e;
	--redGradient: linear-gradient(
		90deg,
		#ff8b94 0%,
		rgba(230, 126, 135, 1) 50%,
		rgba(255, 139, 148, 1) 100%
	);
	--blue: #64b5f6;
	--blueGradient: radial-gradient(circle at 40% 50%, #5aa1db, #64b5f6);
	--darkBlue: #0984e3;
	--liliowy: #a29bfe;
	--mietowy: #55efc4;
	--brzuskwiniowy: #fdcb6e;
	--turkusowyDark: #00cec9;
	--turkusowyLight: #81ecec;

	--widthContent: 1200px;

	--shadowText: 1px 1px 1px #000;
}

/* https://flatuicolors.com/palette/us */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 16px;
	font-family: "Lato", arial, sans-serif;
}

strong {
	font-weight: normal;
}

.heading {
	width: 100%;
	font-weight: 900;
	font-size: 100px;
	color: white;
	font-family: "Tangerine", cursive;
	text-shadow: 1px 1px 1px #000;
	text-shadow: var(--shadowText);
}

/* MOBILE */

@media (max-width: 1200px) {
	.heading {
		font-size: 80px;
	}
}

#nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 150px;
	background: var(--blueGradient);
	z-index: 6;
}

#header {
	width: 100%;
	margin-top: 150px;
	margin-bottom: -4px;
}

#page {
	position: relative;
}

#footer {
	position: relative;
	padding-top: 20px;
	background-color: var(--blue);
}

/* MOBILE SCREEN */
@media (max-width: 900px) {
	#nav {
		height: 70px;
	}

	#header {
		margin-top: 70px;
	}
}

.footer {
	width: var(--widthContent);
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.footer::before,
.policy::before {
	content: "";
	background-size: 14px 100%;
	height: 16px;
	width: 100%;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 100%;
	background-image: linear-gradient(315deg, var(--blue) 25%, transparent 25%),
		linear-gradient(45deg, var(--blue) 25%, transparent 25%);
}

/* LOGO */

.footer-logo {
	height: 100%;
	width: 250px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.footer-logo img {
	width: 150px;
}

.phone-in-footer a {
	display: flex;
	text-decoration: none;
	color: white;
	font-style: italic;
	font-family: "Tangerine", cursive;
	transition: all 0.2s ease-out;
}

.phone-in-footer a:hover {
	color: var(--darkBlue);
}

.phone-in-footer a img {
	width: 26px;
}

.phone-in-footer a strong {
	margin-left: 10px;
	font-size: 50px;
	font-weight: 700;
}

/* CONTENT */

.footer-content {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-end;
}

.socials a img {
	width: 50px;
	transition: -webkit-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
	cursor: pointer;
}

.socials a img:hover {
	-webkit-transform: rotateY(-180deg);
	        transform: rotateY(-180deg);
}

.footer-nav {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.footer-nav-item {
	list-style: none;
}

.footer-nav-item a {
	color: white;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 900;
	letter-spacing: 1px;
	font-size: 22px;
	transition: all 0.2s ease-out;
}

.footer-nav-item a:hover {
	color: var(--darkBlue);
}

.footer-nav-item a.active {
	color: var(--darkBlue);
}

.policy {
	margin-top: 20px;
	background-color: var(--darkBlue);
	position: relative;
}

.policy::before {
	background-image: linear-gradient(
			315deg,
			var(--darkBlue) 25%,
			transparent 25%
		),
		linear-gradient(45deg, var(--darkBlue) 25%, transparent 25%);
}

.wrapper-policy {
	width: var(--widthContent);
	margin: 0 auto;
	display: flex;
	justify-content: flex-end;
}

.policy p {
	height: 100px;
	margin-top: 10px;
	font-size: 13px;
	font-weight: 300;
	color: white;
}
.policy p:nth-child(2) {
	margin-left: 5px;
}

.policy p a {
	color: white;
	text-decoration: none;
}

/* MEDIUM SCREEN */

@media (max-width: 1200px) {
	.footer {
		width: 80%;
	}

	.footer-content {
		width: 60%;
	}
	.wrapper-policy {
		width: 80%;
	}
}

/* MOBILE SCREEN */

@media (max-width: 900px) {
	.footer {
		flex-direction: column;
		align-items: center;
	}

	.footer-logo {
		margin-bottom: 20px;
	}

	.footer-content {
		align-items: center;
	}
	.socials {
		margin-bottom: 20px;
	}
	.footer-nav {
		flex-direction: column;
		align-items: center;
	}
	.footer-nav-item {
		margin: 5px 0;
	}
}

.nav-content {
	display: flex;
	width: var(--widthContent);
	height: 100%;
	margin: 0 auto;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
}

/* NAVIGATION */

.nav-content ul {
	align-self: flex-end;
	display: flex;
	justify-self: center;
}

.nav-item {
	display: flex;
	height: 100%;
	background-color: var(--orange);
	list-style: none;
	-webkit-transform: translateY(30px);
	        transform: translateY(30px);
}
.nav-item a {
	display: block;
	height: 100%;
	padding: 40px 20px;
	font-size: 22px;
	font-weight: 900;
	text-decoration: none;
	text-align: center;
	color: white;
	text-shadow: 0px 0px 1px rgb(0, 0, 0);
	background-color: var(--orange);

	box-sizing: content-box;
	border-bottom: 20px solid transparent;
	transition: -webkit-transform 0.2s ease-out;
	transition: transform 0.2s ease-out;
	transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
}

/* SETTINGS FOR LIST ITEMS */

.nav-item a.active {
	-webkit-transform: translateY(-20px);
	        transform: translateY(-20px);
}

.nav-item:nth-child(2) a,
.nav-item:nth-child(5) a {
	background-color: var(--red);
}

.nav-item a:hover {
	-webkit-transform: translateY(-20px);
	        transform: translateY(-20px);
	background-color: var(--orange);
}
.nav-item:nth-child(2) a:hover,
.nav-item:nth-child(5) a:hover {
	background-color: var(--red);
}

.nav-item:nth-child(3) a {
	background-color: var(--green);
}

.nav-item:nth-child(3) a:hover {
	background-color: var(--green);
}

.nav-item:nth-child(4) a {
	background-color: var(--orange);
}

.nav-item:nth-child(4) a:hover {
	background-color: var(--orange);
}

/* LOGO */

.logo {
	height: 80%;
}
.logo .logo-link {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	text-decoration: none;
}
.logo img {
	width: 100px;
}

.phone-in-nav {
	display: flex;
	align-items: flex-end;
	cursor: pointer;
}

.phone-in-nav a {
	display: flex;
	text-decoration: none;
	color: black;
}

.phone-in-nav a img {
	width: 16px;
}

.phone-in-nav a strong {
	display: block;
	margin-left: 10px;
	font-size: 16px;
	font-style: italic;
}

/* MEDIUM SCREEN */

@media (max-width: 1200px) {
	.nav-content {
		width: 100%;
	}

	.logo {
		margin-left: 20px;
	}
}

/* MOBILE SCREEN */

@media (max-width: 900px) {
	.nav-content {
		justify-content: flex-start;
	}
	.nav-content ul {
		position: fixed;
		top: 70px;
		left: 0;
		bottom: 0;
		width: 350px;
		flex-direction: column;
		justify-content: flex-start;
		justify-self: flex-start;
		background: var(--blue);
		box-shadow: 1px 1px 1px wheat;
		transition: all 0.3s ease-in-out;
	}
	.hide {
		-webkit-transform: translateX(-360px);
		        transform: translateX(-360px);
	}

	.nav-item {
		display: block;
		height: auto;
		-webkit-transform: translateY(0);
		        transform: translateY(0);
		background-color: var(--blue);
	}

	.nav-item a {
		background-color: var(--blue);
		border-bottom: none;
		height: auto;
		padding: 20px 0;
		font-size: 16px;
		font-weight: 400;
	}

	.nav-item a,
	.nav-item:nth-child(2) a,
	.nav-item:nth-child(3) a,
	.nav-item:nth-child(4) a,
	.nav-item:nth-child(5) a {
		background-color: var(--blue);
	}

	.nav-item a:hover,
	.nav-item:nth-child(2) a:hover,
	.nav-item:nth-child(3) a:hover,
	.nav-item:nth-child(4) a:hover,
	.nav-item:nth-child(5) a:hover {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
		background-color: var(--orange);
	}

	.nav-item a.active {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
		background-color: var(--orange);
	}

	/* LOGO */

	.logo {
		flex-direction: row;
	}

	.logo img {
		height: 100%;
		width: auto;
	}

	.phone-in-nav {
		margin-left: 20px;
	}
}

.menu-burger {
	display: none;
	margin-left: 10px;
	cursor: pointer;
}

.menu-burger img {
	display: none;
	width: 30px;
}

img.show-icon {
	display: block;
}

/* MOBILE SCREEN */
@media (max-width: 900px) {
	.menu-burger {
		display: block;
	}
}

.header {
	position: relative;
	width: 100%;
	height: 100%;
}

.header h1 {
	position: absolute;
	width: 100%;
	top: 40%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.2);
	padding: 10px 0;
	z-index: 5;
	color: white;
	letter-spacing: 3px;
	text-align: center;
	font-size: 86px;
	font-family: "Tangerine", cursive;
	text-shadow: 1px 1px 5px #000;
}

.heading-background {
	position: relative;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	width: 100%;
	height: 300px;
	background-color: red;
	z-index: 6;
}

.header h1 span {
	display: block;
	padding: 2px 0;
	font-weight: normal;
}

/* ARROWS BUTTONS */
.slick-next,
.slick-prev {
	z-index: 5;
	right: 5%;
	top: 40%;
	cursor: pointer;
}

.slick-prev {
	left: 5%;
}

/* MOBILE SCREEN */

@media (max-width: 900px) {
	.header h1 {
		font-size: 64px;
	}
}

@media (max-width: 600px) {
	.header h1 {
		font-size: 54px;
	}
}

@media (max-width: 600px) {
	.header h1 {
		font-size: 44px;
	}
}

.wrapper-decorations {
	position: relative;
	top: 0;
	left: 0;
}
.wrap-img {
	position: absolute;
}
.wrap-img img {
	display: block;
	width: 100%;
	opacity: 0.8;
}

.rainbow {
	width: 300px;
	top: 2%;
	right: 5%;
	z-index: 2;
	opacity: 0.8;
	-webkit-transform: rotate(30deg);
	        transform: rotate(30deg);
}

.blueStar {
	width: 50px;
	top: 3%;
	left: 6%;
	z-index: 2;
	-webkit-animation: circle 21s linear infinite;
	        animation: circle 21s linear infinite;
}
.blueStar2 {
	width: 30px;
	top: 20%;
	right: 10%;
	z-index: 2;
	-webkit-animation: rotate 15s linear infinite;
	        animation: rotate 15s linear infinite;
}
.blueStar3 {
	width: 40px;
	bottom: 20%;
	right: 10%;
	z-index: 2;
	-webkit-animation: fly4 32s linear infinite alternate;
	        animation: fly4 32s linear infinite alternate;
}
.starOrange {
	width: 20px;
	top: 6%;
	left: 60%;
	z-index: 2;
	-webkit-animation: circle 22s linear infinite;
	        animation: circle 22s linear infinite;
}

.starOrange2 {
	width: 40px;
	bottom: 60%;
	right: 3%;
	z-index: 2;
	-webkit-animation: rotate 22s linear infinite;
	        animation: rotate 22s linear infinite;
}

.starOrange3 {
	width: 60px;
	top: 27%;
	left: 6%;
	z-index: 2;
	-webkit-animation: flyDown 42s linear infinite alternate;
	        animation: flyDown 42s linear infinite alternate;
}

.starPink-offer {
	width: 50px;
	top: 5%;
	left: 40%;
	z-index: 2;
	-webkit-transform: translateY(0);
	        transform: translateY(0);
	-webkit-animation: rotate 35s linear infinite;
	        animation: rotate 35s linear infinite;
}

.starPink2-offer {
	width: 60px;
	top: 50%;
	right: 4%;
	z-index: 2;
	-webkit-animation: rotate 35s linear infinite;
	        animation: rotate 35s linear infinite;
}

.yellowStar-offer {
	width: 40px;
	bottom: 10%;
	left: 4%;
	z-index: 2;
	-webkit-animation: rotate 25s linear infinite;
	        animation: rotate 25s linear infinite;
}

@-webkit-keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	50% {
		-webkit-transform: rotate(180deg);
		        transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	50% {
		-webkit-transform: rotate(180deg);
		        transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@-webkit-keyframes fly4 {
	0% {
		-webkit-transform: translateY(0) rotate(0deg);
		        transform: translateY(0) rotate(0deg);
	}
	100% {
		-webkit-transform: translateY(-400px) rotate(360deg);
		        transform: translateY(-400px) rotate(360deg);
	}
}

@keyframes fly4 {
	0% {
		-webkit-transform: translateY(0) rotate(0deg);
		        transform: translateY(0) rotate(0deg);
	}
	100% {
		-webkit-transform: translateY(-400px) rotate(360deg);
		        transform: translateY(-400px) rotate(360deg);
	}
}
@-webkit-keyframes flyDown {
	0% {
		-webkit-transform: translateY(0) rotate(0deg);
		        transform: translateY(0) rotate(0deg);
	}
	100% {
		-webkit-transform: translateY(600px) rotate(360deg);
		        transform: translateY(600px) rotate(360deg);
	}
}
@keyframes flyDown {
	0% {
		-webkit-transform: translateY(0) rotate(0deg);
		        transform: translateY(0) rotate(0deg);
	}
	100% {
		-webkit-transform: translateY(600px) rotate(360deg);
		        transform: translateY(600px) rotate(360deg);
	}
}

@-webkit-keyframes circle {
	0% {
		-webkit-transform: rotate(0deg) translate(-30px) rotate(360deg);
		        transform: rotate(0deg) translate(-30px) rotate(360deg);
	}
	100% {
		-webkit-transform: rotate(360deg) translate(-30px) rotate(-360deg);
		        transform: rotate(360deg) translate(-30px) rotate(-360deg);
	}
}

@keyframes circle {
	0% {
		-webkit-transform: rotate(0deg) translate(-30px) rotate(360deg);
		        transform: rotate(0deg) translate(-30px) rotate(360deg);
	}
	100% {
		-webkit-transform: rotate(360deg) translate(-30px) rotate(-360deg);
		        transform: rotate(360deg) translate(-30px) rotate(-360deg);
	}
}

.page-animations {
	width: 100%;
	background: var(--redGradient);
	padding: 50px 0 100px 0;
	position: relative;
}

.animations {
	width: var(--widthContent);
	margin: 0 auto;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.animations h2 {
	width: 100%;
	margin-bottom: 50px;
	font-weight: 900;
	font-size: 100px;
	color: white;
	font-family: "Tangerine", cursive;
	text-shadow: var(--shadowText);
}

.animations p {
	margin-top: 30px;
	color: white;
	font-size: 14px;
	text-align: center;
	line-height: 150%;
}

.animations p span {
	display: block;
	font-size: 16px;
}

.content-animation-box {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	width: 360px;
	height: 500px;
	margin-bottom: 40px;
	padding: 20px;
	text-align: center;
	color: white;
	position: relative;
	z-index: 5;
}

.card {
	display: flex;
	flex-direction: column;
	z-index: 2;
	box-shadow: -20px -20px 0px 0px var(--redTransparent);

	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	padding: 20px;
	border-radius: 10px;
	-webkit-clip-path: circle(5% at 90% 90%);
	        clip-path: circle(5% at 90% 90%);
	transition: all 0.3s ease-in-out;
}

.card.card-show {
	-webkit-clip-path: circle(100%);
	        clip-path: circle(100%);
	background-color: #fff;
}

@-webkit-keyframes show {
	0% {
		-webkit-clip-path: circle(5% at 90% 90%);
		        clip-path: circle(5% at 90% 90%);
	}
	100% {
		-webkit-clip-path: circle(100%);
		        clip-path: circle(100%);
	}
}

@keyframes show {
	0% {
		-webkit-clip-path: circle(5% at 90% 90%);
		        clip-path: circle(5% at 90% 90%);
	}
	100% {
		-webkit-clip-path: circle(100%);
		        clip-path: circle(100%);
	}
}
.animation-img-wrapper {
	width: 250px;
	height: 250px;
	margin: 0 auto;
	background-image: url(/static/media/the-sun.8d859cce.png);
	background-repeat: no-repeat;
	background-size: 250px 250px;
	transition: -webkit-transform 0.5s linear;
	transition: transform 0.5s linear;
	transition: transform 0.5s linear, -webkit-transform 0.5s linear;
}

.content-animation-box:hover .animation-img-wrapper {
	-webkit-transform: rotate(90deg);
	        transform: rotate(90deg);
}

.content-animation-box:hover .animation-img-wrapper div img {
	-webkit-transform: rotate(-90deg);
	        transform: rotate(-90deg);
}

.animation-img-wrapper div {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s linear;
}

.animation-img-wrapper div img {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	transition: -webkit-transform 0.5s linear;
	transition: transform 0.5s linear;
	transition: transform 0.5s linear, -webkit-transform 0.5s linear;
}

.card-heading {
	font-size: 28px;
	letter-spacing: 2px;
	font-weight: 300;
	text-shadow: 1px 1px 1px #000;
	transition: all 0.3s ease-in-out;
}

.card-heading-show {
	font-size: 30px;
	padding-bottom: 20px;
	color: #000;
	text-align: left;
	letter-spacing: 1px;
	font-weight: 900;
	text-shadow: none;
	border-bottom: 5px solid var(--redDark);
}

.content-animation-box .card-description {
	margin-top: 10px;
	line-height: 130%;
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: 300;
	font-style: italic;
}

.card .card-description {
	color: black;
	line-height: 1.5;
	text-align: justify;
}

.content-animation-box .btn-offer {
	display: block;
	margin: 0 auto;
	margin-top: auto;
	padding: 10px 20px;
	border-radius: 50px;
	font-size: 16px;
	font-weight: normal;

	background-color: var(--orange);
	color: white;
	transition: all 0.2s ease-out;
}

.content-animation-box .btn-offer:hover {
	background-color: var(--blue);
}

/* MEDIUM SCREEN */

@media (max-width: 1400px) {
	.animations {
		width: 80%;
	}
}

@media (max-width: 1200px) {
	.animations {
		width: 90%;
	}
}

@media (max-width: 900px) {
	.animations {
		width: 95%;
	}

	.animations h2 {
		font-size: 80px;
	}
}

@media (max-width: 800px) {
	.content-animation-box {
		width: 330px;
		height: 450px;
		margin-bottom: 20px;
		padding: 10px;
		margin-bottom: 30px;
	}
	.animation-img-wrapper {
		width: 200px;
		height: 200px;
		background-size: 200px 200px;
	}
	.animation-img-wrapper div img {
		width: 170px;
		height: 170px;
	}
	.card-heading {
		font-size: 22px;
	}
}

@media (max-width: 750px) {
	.content-animation-box {
		width: 360px;
		height: 410px;
	}
	.card .card-description {
		line-height: 1.3;
	}
}

.page-gallery {
	width: 100%;
	background: var(--greenGradient);
}

.page-gallery h2 {
	width: var(--widthContent);
	margin: 0 auto;
	padding-top: 50px;
}

.gallery {
	width: var(--widthContent);
	padding: 50px 0 100px 0;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.wrapper-img {
	width: 200px;
	height: 200px;
	overflow: hidden;
	margin: 5px;
	background-color: #000;
}

.wrapper-img img {
	display: block;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0.8;
	transition: all 0.2s linear;
}

.wrapper-img img:hover {
	-webkit-transform: scale(1.2);
	        transform: scale(1.2);
	opacity: 1;
}

.wrapper-img img.duze {
	display: none;
}

/* MEDIUM SCREEN */

@media (max-width: 1200px) {
	.page-gallery h2 {
		width: 80%;
	}
	.gallery {
		width: 80%;
	}
}

@media (max-width: 900px) {
	.page-gallery h2 {
		width: 90%;
	}
	.gallery {
		width: 90%;
	}

	.wrapper-img {
		width: 300px;
		height: 300px;
	}
}

@media (max-width: 500px) {
	.wrapper-img {
		width: 200px;
		height: 200px;
	}
}

.btn {
	margin-top: 50px;
	background-color: var(--red);
	overflow: hidden;
	border: none;
	cursor: pointer;
}

.btn a {
	position: relative;
	display: block;
	padding: 20px 40px;
	text-align: center;
	font-size: 22px;
	color: white;
	font-weight: 700;
	text-decoration: none;
	z-index: 1;
	transition: all 0.2s ease-out;
}

.btn a::before {
	content: "";
	position: absolute;
	height: 100%;
	top: 50%;
	width: 120%;
	left: -10%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	background-color: var(--red);
	transition: all 0.2s ease-out;
	border-radius: 50%;
	z-index: -1;
}

.btn a:hover {
	background-color: var(--blue);
}

.btn a:hover:before {
	height: 50%;
	width: 0%;
	left: 50%;
}

/* MOBILE */

@media (max-width: 900px) {
	.btn a {
		font-size: 16px;
		padding: 15px 30px;
	}
}

.page-priceList {
	background: var(--orangeGradient);
}

section.priceList {
	width: calc(var(--widthContent) * 1.1);
	max-width: 100%;
	margin: 0 auto;
	padding: 50px 0 0 0;
}

section.priceList h2 {
	width: var(--widthContent);
	margin: 0 auto;
}

section.priceList h2 sup {
	font-size: 50px;
}

.priceList-wrapper {
	width: 100%;
	margin: 30px 0;
}

.priceList-box {
	display: flex;
	flex-direction: column;
	width: 350px;
	height: 490px;
	padding-bottom: 10px;
	margin: 20px auto 50px auto;
	border-radius: 10px;
	background-color: #eee;
	transition: all 0.1s ease-out;
}

.priceList-box:hover {
	-webkit-transform: translateY(-20px);
	        transform: translateY(-20px);
	box-shadow: 1px 8px 5px rgba(0, 0, 0, 0.2);
}

.priceList-box h4 {
	padding: 20px 0;
	margin-bottom: 10px;
	font-size: 20px;
	text-align: center;
	font-weight: 500;
	letter-spacing: 1px;
	background-color: var(--red);
	color: white;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	text-shadow: 0 1px 1px #bbb;
}

.priceList-box h4.blue {
	background-color: var(--blue);
}

.priceList-block {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 20px;
	padding: 0 20px 10px;
	border-bottom: 1px dashed #ddd;
}

.priceList-block p {
	width: 80%;
	font-weight: 300;
	color: #333;
	font-size: 15px;
	text-align: justify;
}

.priceList-block span {
	display: block;
	width: 100%;
	text-align: right;
	text-shadow: 0 1px 1px #bbb;
	font-size: 14px;
}

.btn-priceList {
	align-self: center;
	margin: 0;
	margin-top: auto;
	width: 180px;
	border-radius: 50px;
}
.btn-priceList a {
	padding: 15px 30px;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 300;
}

/* DESCRIPTION */

.priceList-description {
	width: 100%;
	background: var(--greenGradient);
	padding: 50px 0;
}

.priceList-description ul {
	width: var(--widthContent);
	margin: 0 auto;
	list-style: none;
}

.priceList-description ul li {
	margin: 5px 0;
	color: white;
	font-size: 14px;
}

.priceList-description ul li a {
	color: white;
	text-decoration: none;
}
.priceList-description ul li a:hover {
	color: var(--red);
	text-shadow: 1px 1px 1px black;
}

/* SLIDER ELEMENTS */

/* PAGINATION BULLETS */
.swiper-pagination-bullet-active {
	background: var(--darkBlue);
}

/* NAVIGATION ARROWS */
.swiper-button-prev,
.swiper-button-next {
	color: var(--darkBlue);
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
	left: 0;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
	right: 0;
}

/* MEDIUM SCREEN */

@media (max-width: 1200px) {
	section.priceList {
		width: 80%;
	}

	section.priceList h2 {
		width: 80%;
	}

	.priceList-description ul {
		width: 80%;
	}
}

/* MOBILE SCREEN */

@media (max-width: 700px) {
	.priceList-box:hover {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@media (max-width: 700px) {
	.priceList-box {
		width: 330px;
		height: 460px;
	}
	.priceList-block p {
		font-size: 12px;
	}
	.priceList-block span {
		font-size: 11px;
	}
	.btn-priceList {
		width: 150px;
	}
	.btn-priceList a {
		padding: 10px 20px;
		font-size: 12px;
	}

	.swiper-button-prev::after,
	.swiper-button-next::after {
		font-size: 28px;
	}
}

.page-contact {
	width: 100%;
	background: var(--redGradient);
	padding: 50px 0 100px 0;
}

.contact {
	width: var(--widthContent);
	margin: 0 auto;
}

.contact p {
	width: 40%;
	color: white;
	letter-spacing: 1px;
}

.contact-methods {
	display: flex;
	justify-content: space-around;
	margin-top: 30px;
	margin-bottom: 70px;
}

.method-box {
	width: 33%;
	display: flex;
}

.method-box img {
	width: 50px;
	align-self: flex-start;
}

.method-box img svg {
	fill: white;
}

.method-box-content {
	flex-grow: 1;
	margin-left: 30px;
}

.method-name {
	margin-bottom: 10px;
	font-size: 24px;
	letter-spacing: 2px;
	color: white;
	text-shadow: var(--shadowText);
}

p.method-text {
	width: 100%;
	font-weight: 300;
	color: #ddd;
}

.contact-link img {
	display: block;
	width: 40px;
}

/* CONTACT CONTENT */

.contact-content {
	display: flex;
}

/* PHOTO CONTACT */

.contact-photo {
	width: 50%;
}

.contact-photo img {
	display: block;
	width: 95%;
	box-shadow: -2px -2px 40px 1px black;
}

/* CONTACT FORM */

.contact-form {
	width: 50%;
}

.contact-form h3 {
	text-align: center;
	font-size: 50px;
}

.contact-form form {
	text-align: center;
}

/* MEDIUM SCREEN */
@media (max-width: 1400px) {
	.contact {
		width: 90%;
	}
}
@media (max-width: 1200px) {
	.contact p {
		width: 100%;
	}
	.contact-methods {
		flex-wrap: wrap;
	}
	.method-box-content {
		width: 100%;
	}
	.method-box {
		width: 100%;
		margin-bottom: 20px;
	}

	.contact-content {
		flex-direction: column;
		align-items: center;
	}
	.contact-photo {
		width: 80%;
		margin-bottom: 40px;
	}
}

.wrapper-decorations {
	position: absolute;
	width: 100%;
	height: 100%;
}
.wrap-img {
	position: absolute;
}
.wrap-img img {
	display: block;
	width: 100%;
}

.starPink {
	width: 50px;
	top: 6%;
	left: 50%;
	-webkit-transform: rotate(0);
	        transform: rotate(0);
	z-index: 5;
}

.starPink img {
	-webkit-animation: rotate 15s linear infinite;
	        animation: rotate 15s linear infinite;
}

.butterflyOpacity {
	width: 150px;
	z-index: 5;
	top: 5%;
	left: 12%;
}

.flower {
	width: 130px;
	z-index: 5;
	bottom: 2%;
	right: 5%;
}

@-webkit-keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	50% {
		-webkit-transform: rotate(180deg);
		        transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	50% {
		-webkit-transform: rotate(180deg);
		        transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

/* MEDIUM SCREEN */

@media (max-width: 1200px) {
	.starPink {
		top: 60px;
		left: 60%;
	}

	.butterflyOpacity {
		top: 60px;
		left: 35%;
	}
}

/* MOBILE */

@media (max-width: 900px) {
	.starPink {
		width: 30px;
	}
	.butterflyOpacity {
		width: 100px;
	}
	.flower {
		width: 100px;
		bottom: 3%;
	}
}

@media (max-width: 700px) {
	.starPink {
		left: 80%;
	}
	.butterflyOpacity {
		left: 45%;
	}
	.flower {
		right: 1%;
		bottom: 1%;
	}
}

@media (max-width: 700px) {
	.starPink {
		left: 90%;
	}
	.butterflyOpacity {
		left: 55%;
	}
	.flower {
		right: 1%;
		bottom: 1%;
	}
}

.about-in-home-page {
	padding-top: 100px;
	background: var(--greenGradient);
	position: relative;
}

.wrapper-about-in-home-page {
	width: var(--widthContent);
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
}

.content-about-in-home-page {
	width: 50%;
	padding: 50px 0;
	color: white;
}

.content-about-in-home-page h2 {
	margin-bottom: 25px;
}

.content-about-in-home-page p {
	line-height: 200%;
	letter-spacing: 0.2px;
}

.content-about-in-home-page p span {
	display: block;
	width: 110%;
	margin-bottom: 5px;
	font-size: 18px;
	font-weight: 700;
}

/* PHOTOS */

.photos-about-in-home-page {
	position: relative;
	width: 50%;
}

.photos-about-in-home-page img {
	position: absolute;
	left: 40%;
	border-radius: 50%;
}

.photos-about-in-home-page img:nth-child(1) {
	top: 180px;
	-webkit-transform: translateX(190px);
	        transform: translateX(190px);
	width: 240px;
	height: 240px;
	z-index: 3;
}
.photos-about-in-home-page img:nth-child(2) {
	top: 0;
	width: 335px;
	height: 335px;
	z-index: 2;
}
.photos-about-in-home-page img:nth-child(3) {
	top: 230px;
	-webkit-transform: translateX(-120px);
	        transform: translateX(-120px);
	width: 205px;
	height: 205px;
}

/* MEDIUM SCREEN */

@media (max-width: 1400px) {
	.photos-about-in-home-page img {
		left: 25%;
	}
	.photos-about-in-home-page img:nth-child(1) {
		width: 200px;
		height: 200px;
	}
	.photos-about-in-home-page img:nth-child(2) {
		width: 300px;
		height: 300px;
	}
}

@media (max-width: 1200px) {
	.about-in-home-page {
		padding-top: 0;
	}
	.wrapper-about-in-home-page {
		width: 100%;
		padding-bottom: 230px;
		flex-direction: column;
		align-items: center;
	}

	.content-about-in-home-page,
	.photos-about-in-home-page {
		width: 80%;
	}

	.photos-about-in-home-page img {
		left: 0;
	}
	.photos-about-in-home-page img:nth-child(1),
	.photos-about-in-home-page img:nth-child(2),
	.photos-about-in-home-page img:nth-child(3) {
		top: 0;
		width: 200px;
		height: 200px;
		-webkit-transform: translateX(0px);
		        transform: translateX(0px);
	}
	.photos-about-in-home-page img:nth-child(2) {
		left: 160px;
	}
	.photos-about-in-home-page img:nth-child(1) {
		left: 320px;
	}
}

/* MOBILE SCREEN */

@media (max-width: 900px) {
	.content-about-in-home-page p {
		font-size: 14px;
	}

	.content-about-in-home-page p span {
		font-size: 16px;
	}
	.photos-about-in-home-page img:nth-child(1),
	.photos-about-in-home-page img:nth-child(2),
	.photos-about-in-home-page img:nth-child(3) {
		width: 160px;
		height: 160px;
	}
	.photos-about-in-home-page img:nth-child(2) {
		left: 140px;
	}
	.photos-about-in-home-page img:nth-child(1) {
		left: 280px;
	}
}

@media (max-width: 500px) {
	.photos-about-in-home-page {
		padding-bottom: 70px;
	}
	.photos-about-in-home-page img:nth-child(1),
	.photos-about-in-home-page img:nth-child(2),
	.photos-about-in-home-page img:nth-child(3) {
		width: 150px;
		height: 150px;
	}
	.photos-about-in-home-page img:nth-child(2) {
		left: 50%;
		-webkit-transform: translateX(-50%) translateY(130px);
		        transform: translateX(-50%) translateY(130px);
	}
	.photos-about-in-home-page img:nth-child(1) {
		left: 50%;
	}
	.photos-about-in-home-page img:nth-child(3) {
		left: 50%;
		-webkit-transform: translateX(-100%);
		        transform: translateX(-100%);
	}
}

.wrapper-decorations {
	position: absolute;
	width: 100%;
	height: 100%;
}
.wrap-img {
	position: absolute;
}
.wrap-img img {
	display: block;
	width: 100%;
}

.unicorns {
	width: 120px;
	top: 3%;
	right: 15%;
	z-index: 5;
	opacity: 0.8;
}

.redHeartInStar {
	width: 80px;
	top: 25%;
	left: 7%;
	z-index: 5;
	opacity: 0.7;
	-webkit-animation: rotate 33s linear infinite;
	        animation: rotate 33s linear infinite;
}

.threeBalloons {
	width: 100px;
	bottom: 10%;
	right: 5%;
	z-index: 5;
	-webkit-transform: translateY(0) translateX(0);
	        transform: translateY(0) translateX(0);
	-webkit-animation: flyUp 13s linear infinite;
	        animation: flyUp 13s linear infinite;
}

@-webkit-keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	50% {
		-webkit-transform: rotate(180deg);
		        transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	50% {
		-webkit-transform: rotate(180deg);
		        transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@-webkit-keyframes bounce {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	40% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	45% {
		-webkit-transform: scale(1.2);
		        transform: scale(1.2);
	}
	50% {
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
	}
	55% {
		-webkit-transform: scale(1.1);
		        transform: scale(1.1);
	}
	60% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@keyframes bounce {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	40% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	45% {
		-webkit-transform: scale(1.2);
		        transform: scale(1.2);
	}
	50% {
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
	}
	55% {
		-webkit-transform: scale(1.1);
		        transform: scale(1.1);
	}
	60% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@-webkit-keyframes flyUp {
	0% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
		opacity: 0;
	}
	10% {
		opacity: 1;
	}

	20% {
		-webkit-transform: translateY(-60%) translateX(20px);
		        transform: translateY(-60%) translateX(20px);
	}

	40% {
		-webkit-transform: translateY(-120%) translateX(0);
		        transform: translateY(-120%) translateX(0);
	}

	60% {
		-webkit-transform: translateY(-180%) translateX(-20px);
		        transform: translateY(-180%) translateX(-20px);
	}

	80% {
		-webkit-transform: translateY(-240%) translateX(0);
		        transform: translateY(-240%) translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(-300%) translateX(10px);
		        transform: translateY(-300%) translateX(10px);
		opacity: 0;
	}
}

@keyframes flyUp {
	0% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
		opacity: 0;
	}
	10% {
		opacity: 1;
	}

	20% {
		-webkit-transform: translateY(-60%) translateX(20px);
		        transform: translateY(-60%) translateX(20px);
	}

	40% {
		-webkit-transform: translateY(-120%) translateX(0);
		        transform: translateY(-120%) translateX(0);
	}

	60% {
		-webkit-transform: translateY(-180%) translateX(-20px);
		        transform: translateY(-180%) translateX(-20px);
	}

	80% {
		-webkit-transform: translateY(-240%) translateX(0);
		        transform: translateY(-240%) translateX(0);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateY(-300%) translateX(10px);
		        transform: translateY(-300%) translateX(10px);
		opacity: 0;
	}
}

@media (max-width: 1400px) {
	.redHeartInStar {
		width: 70px;
		top: 20%;
		left: 3%;
	}
}

@media (max-width: 1200px) {
	.unicorns {
		width: 100px;
		top: 5%;
	}
	.threeBalloons {
		width: 90px;
		right: 2%;
		z-index: 5;
	}
}
@media (max-width: 1200px) {
	.unicorns {
		top: 2%;
	}
}

@media (max-width: 700px) {
	.redHeartInStar {
		width: 50px;
		top: 30%;
		left: 2%;
	}
}

.services {
	padding-top: 100px;
	padding-bottom: 50px;
	background: var(--redGradient);
	position: relative;
}

.wrapper-services {
	width: var(--widthContent);
	margin: 10px auto 50px auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	color: white;
}

.reverse {
	flex-direction: row-reverse;
}

/* CONTNENT SERVICES */

.content-services {
	width: 50%;
}

.content-services h2 {
	margin-bottom: 25px;
}

.content-services div {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.content-services div p {
	width: 100%;
	font-size: 18px;
}

/* OFFER LIST */

.content-services div ul {
	display: flex;
	flex-wrap: wrap;
	flex-grow: 1;
	margin: 5px 0 20px 25px;
	line-height: 150%;
}

.content-services div ul li {
	min-width: 30%;
	margin-right: 20px;
	list-style-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAANeSURBVDhPrVTda1N3GH7OSUyapB9p+pXTJmlFm7ZxEhho6U3toFAEUapp40cFb7wTRcG7KQgDL/wTdjN2MaadiAUvBC9cBtuaFTW2qdZqa039jIlJe6IeT06y55weuznswLkH3vP7et/n/f3ej4P3uAdE7gKd5vI/QzRHaICnCBSTwEgZEMztT4ZByNv5SoAlFI12uiVpYho4c4FrQ+MTYRC+A76sqq8nDyxSb+9XNYFAbBNwSj/7B1wUx8r04zAI+fH7envLL3I556V4/FHz1q07HG53lh62GVqARFlPaaE06htrwSDk2zIQxbcvZblZ1TRpLJFQ2vr7O7nXx2M9nsuUV7ou8dYcPwoj+FNAyBsO5yFJA9empiaZIKG90dvTVen0LsRiV74AxisAL5mWqdvGW+xk4ko0zlGypMhw73kQSBg3pMH0k0Si6Kmp+VkUBPuBxcX4/RfpR67GxniVx+MM2u2+oYaGvjvAbqvD0do+OPhb1/Dws85IJN0Rjb7sAq6SRnkARFfLg8odZN+Q9PtnCnZ72qIowf2p1EQpEmkYun7ddyKfV7t37dpCh1JBUeJ8ie1NsejZ291tmR0dvaUCw0zk18YNddDLDJ9a3ZFK9dkLJbdLEDLcFvbHYmJZFOt69uwpy0tLly+Mj8fHbiQqVVVdqq2oYIHAQ7shJvA0b1deJdQRAn5kPaY2P50b8WWzDBvKaqkUhCDUfTs7e+/q9GzLc5vtFyvEB5ooZgbC4Rombox62WGjN8ws/x308isDnnDKcmmkqclFAxs0bdmfz7P+1McNilIXXZyfjPb06MnAzPnzG2lzxTAmVmOotxt7+TDd2CsDgZ/E5ubc8YcPN2matuVsOh3neZDip8zxFm/cra3+3MLCKPWdm/kqk+YvwhlmkIGdqA2FAhaL5UnT5OT8YH19e9jhkPelUu9oGHICtxk0iUbL7cBjjuT/EMICUMuqPUSvXpvLdbOqre2P18lkgTW3fR2fIlRXt9r9/mf5ZHIvs3jOtFsTogz0MxHf0ds3SqHgzCSTx14DB9k9P/Dv41FleT3PmC9Urph8BiaAdeyM7+d8voE7VuvReUDP/L9iNYZrgbFtYfxOcqpagYtsr99XTj4T/PF2s3iPmMv/B2zPneZ0DQB/AjUBIpYxgK8XAAAAAElFTkSuQmCC);
	font-size: 18px;
	font-style: italic;
}

.content-services div .list li {
	width: 100%;
}

/* PHOTO */

.photos-services {
	width: 50%;
	display: flex;
	justify-content: center;
	align-self: center;
}

.photos-services img {
	display: block;
	width: 80%;
	box-shadow: -10px -10px 60px 2px black;
}

.photos-services .left-img {
	box-shadow: 10px 10px 60px 2px black;
}

.wrapper-services .text {
	width: 100%;
	font-size: 28px;
	letter-spacing: 2px;
	text-align: center;
}

.wrapper-services .text:nth-of-type(1) {
	margin-top: 90px;
}

@media (max-width: 1400px) {
	.content-services {
		width: 50%;
	}
}

@media (max-width: 1200px) {
	.wrapper-services {
		width: 80%;
	}
	.photos-services img {
		width: 90%;
	}

	.wrapper-services .text {
		font-size: 20px;
	}
	.wrapper-services .text:nth-of-type(1) {
		margin-top: 50px;
	}
}

@media (max-width: 900px) {
	.services {
		padding-top: 50px;
		padding-bottom: 30px;
	}
	.wrapper-services {
		flex-direction: column;
	}
	.content-services {
		width: 100%;
	}
	.content-services div p {
		font-size: 16px;
	}

	.content-services div ul li {
		font-size: 16px;
		margin-right: 0;
	}
	.photos-services {
		margin-top: 20px;
		width: 100%;
	}
}

.wrapper-decorations {
	position: absolute;
	width: 100%;
	height: 100%;
}
.wrap-img {
	position: absolute;
}
.wrap-img img {
	display: block;
	width: 100%;
}

.smilingSun {
	width: 120px;
	top: -50px;
	left: 15%;
	z-index: 2;
}

.smilingCloud {
	width: 120px;
	top: 20px;
	right: 10%;
	z-index: 3;
	-webkit-animation: fly3 30s linear infinite alternate;
	        animation: fly3 30s linear infinite alternate;
}
.blueCloud2 {
	width: 120px;
	top: 60px;
	left: 5%;
	z-index: 4;
	-webkit-animation: fly2 35s linear infinite alternate;
	        animation: fly2 35s linear infinite alternate;
}
.blueCloud {
	width: 120px;
	top: -10px;
	left: 8%;
	z-index: 3;
	-webkit-animation: fly 25s 1s linear infinite alternate;
	        animation: fly 25s 1s linear infinite alternate;
}

@-webkit-keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	50% {
		-webkit-transform: rotate(180deg);
		        transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}

	50% {
		-webkit-transform: rotate(180deg);
		        transform: rotate(180deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@-webkit-keyframes bounce {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	40% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	45% {
		-webkit-transform: scale(1.2);
		        transform: scale(1.2);
	}
	50% {
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
	}
	55% {
		-webkit-transform: scale(1.1);
		        transform: scale(1.1);
	}
	60% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@keyframes bounce {
	0% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}

	40% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	45% {
		-webkit-transform: scale(1.2);
		        transform: scale(1.2);
	}
	50% {
		-webkit-transform: scale(0.9);
		        transform: scale(0.9);
	}
	55% {
		-webkit-transform: scale(1.1);
		        transform: scale(1.1);
	}
	60% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
	100% {
		-webkit-transform: scale(1);
		        transform: scale(1);
	}
}

@-webkit-keyframes fly {
	0% {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}

	20% {
		-webkit-transform: translateX(60%);
		        transform: translateX(60%);
	}

	40% {
		-webkit-transform: translateX(120%);
		        transform: translateX(120%);
	}

	60% {
		-webkit-transform: translateX(180%);
		        transform: translateX(180%);
	}

	80% {
		-webkit-transform: translateX(240%);
		        transform: translateX(240%);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(300%);
		        transform: translateX(300%);
	}
}

@keyframes fly {
	0% {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
	}

	20% {
		-webkit-transform: translateX(60%);
		        transform: translateX(60%);
	}

	40% {
		-webkit-transform: translateX(120%);
		        transform: translateX(120%);
	}

	60% {
		-webkit-transform: translateX(180%);
		        transform: translateX(180%);
	}

	80% {
		-webkit-transform: translateX(240%);
		        transform: translateX(240%);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(300%);
		        transform: translateX(300%);
	}
}

@-webkit-keyframes fly2 {
	0% {
		-webkit-transform: translateX(0) translateX(0);
		        transform: translateX(0) translateX(0);
	}

	20% {
		-webkit-transform: translateX(100%) translateY(20px);
		        transform: translateX(100%) translateY(20px);
	}

	40% {
		-webkit-transform: translateX(200%) translateY(-30px);
		        transform: translateX(200%) translateY(-30px);
	}

	60% {
		-webkit-transform: translateX(300%) translateY(-50px);
		        transform: translateX(300%) translateY(-50px);
	}

	80% {
		-webkit-transform: translateX(400%) translateY(-60px);
		        transform: translateX(400%) translateY(-60px);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(480%) translateY(-40px);
		        transform: translateX(480%) translateY(-40px);
	}
}

@keyframes fly2 {
	0% {
		-webkit-transform: translateX(0) translateX(0);
		        transform: translateX(0) translateX(0);
	}

	20% {
		-webkit-transform: translateX(100%) translateY(20px);
		        transform: translateX(100%) translateY(20px);
	}

	40% {
		-webkit-transform: translateX(200%) translateY(-30px);
		        transform: translateX(200%) translateY(-30px);
	}

	60% {
		-webkit-transform: translateX(300%) translateY(-50px);
		        transform: translateX(300%) translateY(-50px);
	}

	80% {
		-webkit-transform: translateX(400%) translateY(-60px);
		        transform: translateX(400%) translateY(-60px);
		opacity: 1;
	}
	100% {
		-webkit-transform: translateX(480%) translateY(-40px);
		        transform: translateX(480%) translateY(-40px);
	}
}

@-webkit-keyframes fly3 {
	0% {
		-webkit-transform: translateX(0) translateX(0);
		        transform: translateX(0) translateX(0);
	}

	20% {
		-webkit-transform: translateX(-50%) translateY(20px);
		        transform: translateX(-50%) translateY(20px);
	}

	40% {
		-webkit-transform: translateX(-100%) translateY(-20px);
		        transform: translateX(-100%) translateY(-20px);
	}

	60% {
		-webkit-transform: translateX(-150%) translateY(-30px);
		        transform: translateX(-150%) translateY(-30px);
	}

	80% {
		-webkit-transform: translateX(-200%) translateY(0);
		        transform: translateX(-200%) translateY(0);
	}
	100% {
		-webkit-transform: translateX(-250%) translateY(20px);
		        transform: translateX(-250%) translateY(20px);
	}
}

@keyframes fly3 {
	0% {
		-webkit-transform: translateX(0) translateX(0);
		        transform: translateX(0) translateX(0);
	}

	20% {
		-webkit-transform: translateX(-50%) translateY(20px);
		        transform: translateX(-50%) translateY(20px);
	}

	40% {
		-webkit-transform: translateX(-100%) translateY(-20px);
		        transform: translateX(-100%) translateY(-20px);
	}

	60% {
		-webkit-transform: translateX(-150%) translateY(-30px);
		        transform: translateX(-150%) translateY(-30px);
	}

	80% {
		-webkit-transform: translateX(-200%) translateY(0);
		        transform: translateX(-200%) translateY(0);
	}
	100% {
		-webkit-transform: translateX(-250%) translateY(20px);
		        transform: translateX(-250%) translateY(20px);
	}
}

.advantages {
	padding: 100px 0 50px 0;
	background: var(--orangeGradient);
	color: white;
	position: relative;
}

.content-advantages {
	width: var(--widthContent);
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.content-advantages h2 {
	text-align: center;
	z-index: 4;
}

.advantage-box {
	flex-basis: 25%;
	text-align: center;
}

.advantage-box img {
	display: block;
	width: 150px;
	height: 150px;
	margin: 20px auto;
}

.advantage-box h4 {
	margin-bottom: 10px;
	font-size: 26px;
	font-style: italic;
	letter-spacing: 2px;
	text-align: center;
	text-shadow: 1px 1px 1px #000;
}

.advantage-box p {
	width: 90%;
	margin: 0 auto;
	font-size: 16px;
	line-height: 150%;
}

/* MEDIUM SCREEN */

@media (max-width: 1200px) {
	.content-advantages {
		width: 80%;
	}
}

.certificates {
	position: relative;
	background: var(--greenGradient);
}

.certificates-wrapper {
	width: var(--widthContent);
	margin: 0 auto;
	padding: 50px 0 100px 0;
}

.certificates-wrapper h2 {
	margin-bottom: 50px;
	text-align: center;
}

.certificates-gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.certificate-box {
	width: 150px;
	margin: 10px 30px;
	cursor: pointer;
}

.certificate-box img {
	width: 100%;
	border-radius: 10px;
	transition: all 0.2s linear;
}

.certificate-box img:hover {
	-webkit-transform: perspective(900px) rotateY(35deg);
	        transform: perspective(900px) rotateY(35deg);
}

/* MEDIUM SCREEN */

@media (max-width: 1200px) {
	.certificates-wrapper {
		width: 80%;
	}
}

/* MOBILE SCREEN */

@media (max-width: 900px) {
	.certificates-wrapper {
		width: 90%;
	}
	.certificate-box {
		margin: 10px 20px;
	}
}
@media (max-width: 700px) {
	.certificate-box {
		margin: 10px 10px;
		width: 130px;
	}
}
@media (max-width: 500px) {
	.certificate-box {
		/* width: 100px; */
	}
}

.error-page {
	margin-top: 150px;
	height: 500px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.error-page p {
	margin-bottom: 20px;
	font-size: 28px;
}
.error-page a {
	-webkit-text-decoration: 0;
	        text-decoration: 0;
	color: black;
	font-style: italic;
}
.error-page a:hover {
	color: var(--red);
}

@media (max-width: 900px) {
	.error-page {
		margin-top: 70px;
	}
}

