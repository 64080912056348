.nav-content {
	display: flex;
	width: var(--widthContent);
	height: 100%;
	margin: 0 auto;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
}

/* NAVIGATION */

.nav-content ul {
	align-self: flex-end;
	display: flex;
	justify-self: center;
}

.nav-item {
	display: flex;
	height: 100%;
	background-color: var(--orange);
	list-style: none;
	transform: translateY(30px);
}
.nav-item a {
	display: block;
	height: 100%;
	padding: 40px 20px;
	font-size: 22px;
	font-weight: 900;
	text-decoration: none;
	text-align: center;
	color: white;
	text-shadow: 0px 0px 1px rgb(0, 0, 0);
	background-color: var(--orange);

	box-sizing: content-box;
	border-bottom: 20px solid transparent;
	transition: transform 0.2s ease-out;
}

/* SETTINGS FOR LIST ITEMS */

.nav-item a.active {
	transform: translateY(-20px);
}

.nav-item:nth-child(2) a,
.nav-item:nth-child(5) a {
	background-color: var(--red);
}

.nav-item a:hover {
	transform: translateY(-20px);
	background-color: var(--orange);
}
.nav-item:nth-child(2) a:hover,
.nav-item:nth-child(5) a:hover {
	background-color: var(--red);
}

.nav-item:nth-child(3) a {
	background-color: var(--green);
}

.nav-item:nth-child(3) a:hover {
	background-color: var(--green);
}

.nav-item:nth-child(4) a {
	background-color: var(--orange);
}

.nav-item:nth-child(4) a:hover {
	background-color: var(--orange);
}

/* LOGO */

.logo {
	height: 80%;
}
.logo .logo-link {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	text-decoration: none;
}
.logo img {
	width: 100px;
}

.phone-in-nav {
	display: flex;
	align-items: flex-end;
	cursor: pointer;
}

.phone-in-nav a {
	display: flex;
	text-decoration: none;
	color: black;
}

.phone-in-nav a img {
	width: 16px;
}

.phone-in-nav a strong {
	display: block;
	margin-left: 10px;
	font-size: 16px;
	font-style: italic;
}

/* MEDIUM SCREEN */

@media (max-width: 1200px) {
	.nav-content {
		width: 100%;
	}

	.logo {
		margin-left: 20px;
	}
}

/* MOBILE SCREEN */

@media (max-width: 900px) {
	.nav-content {
		justify-content: flex-start;
	}
	.nav-content ul {
		position: fixed;
		top: 70px;
		left: 0;
		bottom: 0;
		width: 350px;
		flex-direction: column;
		justify-content: flex-start;
		justify-self: flex-start;
		background: var(--blue);
		box-shadow: 1px 1px 1px wheat;
		transition: all 0.3s ease-in-out;
	}
	.hide {
		transform: translateX(-360px);
	}

	.nav-item {
		display: block;
		height: auto;
		transform: translateY(0);
		background-color: var(--blue);
	}

	.nav-item a {
		background-color: var(--blue);
		border-bottom: none;
		height: auto;
		padding: 20px 0;
		font-size: 16px;
		font-weight: 400;
	}

	.nav-item a,
	.nav-item:nth-child(2) a,
	.nav-item:nth-child(3) a,
	.nav-item:nth-child(4) a,
	.nav-item:nth-child(5) a {
		background-color: var(--blue);
	}

	.nav-item a:hover,
	.nav-item:nth-child(2) a:hover,
	.nav-item:nth-child(3) a:hover,
	.nav-item:nth-child(4) a:hover,
	.nav-item:nth-child(5) a:hover {
		transform: translateY(0);
		background-color: var(--orange);
	}

	.nav-item a.active {
		transform: translateY(0);
		background-color: var(--orange);
	}

	/* LOGO */

	.logo {
		flex-direction: row;
	}

	.logo img {
		height: 100%;
		width: auto;
	}

	.phone-in-nav {
		margin-left: 20px;
	}
}
