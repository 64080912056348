:root {
	--orange: #ff9e5f;
	--orangeGradient: radial-gradient(
		circle at 50% 50%,
		#e68f55,
		#ea9156,
		#ed9358,
		#f19559,
		#f4985b,
		#f89a5c,
		#fb9c5e,
		#ff9e5f
	);
	--green: #82b334;
	--greenGradient: linear-gradient(
		90deg,
		#82b334 0%,
		#91c63c 50%,
		#82b334 100%
	);

	--red: #ff8b94;
	--redDark: #c95b66;
	--redTransparent: #c95b663e;
	--redGradient: linear-gradient(
		90deg,
		#ff8b94 0%,
		rgba(230, 126, 135, 1) 50%,
		rgba(255, 139, 148, 1) 100%
	);
	--blue: #64b5f6;
	--blueGradient: radial-gradient(circle at 40% 50%, #5aa1db, #64b5f6);
	--darkBlue: #0984e3;
	--liliowy: #a29bfe;
	--mietowy: #55efc4;
	--brzuskwiniowy: #fdcb6e;
	--turkusowyDark: #00cec9;
	--turkusowyLight: #81ecec;

	--widthContent: 1200px;

	--shadowText: 1px 1px 1px #000;
}

/* https://flatuicolors.com/palette/us */

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	font-size: 16px;
	font-family: "Lato", arial, sans-serif;
}

strong {
	font-weight: normal;
}

.heading {
	width: 100%;
	font-weight: 900;
	font-size: 100px;
	color: white;
	font-family: "Tangerine", cursive;
	text-shadow: 1px 1px 1px #000;
	text-shadow: var(--shadowText);
}

/* MOBILE */

@media (max-width: 1200px) {
	.heading {
		font-size: 80px;
	}
}
