.page-gallery {
	width: 100%;
	background: var(--greenGradient);
}

.page-gallery h2 {
	width: var(--widthContent);
	margin: 0 auto;
	padding-top: 50px;
}

.gallery {
	width: var(--widthContent);
	padding: 50px 0 100px 0;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.wrapper-img {
	width: 200px;
	height: 200px;
	overflow: hidden;
	margin: 5px;
	background-color: #000;
}

.wrapper-img img {
	display: block;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0.8;
	transition: all 0.2s linear;
}

.wrapper-img img:hover {
	transform: scale(1.2);
	opacity: 1;
}

.wrapper-img img.duze {
	display: none;
}

/* MEDIUM SCREEN */

@media (max-width: 1200px) {
	.page-gallery h2 {
		width: 80%;
	}
	.gallery {
		width: 80%;
	}
}

@media (max-width: 900px) {
	.page-gallery h2 {
		width: 90%;
	}
	.gallery {
		width: 90%;
	}

	.wrapper-img {
		width: 300px;
		height: 300px;
	}
}

@media (max-width: 500px) {
	.wrapper-img {
		width: 200px;
		height: 200px;
	}
}
