.btn {
	margin-top: 50px;
	background-color: var(--red);
	overflow: hidden;
	border: none;
	cursor: pointer;
}

.btn a {
	position: relative;
	display: block;
	padding: 20px 40px;
	text-align: center;
	font-size: 22px;
	color: white;
	font-weight: 700;
	text-decoration: none;
	z-index: 1;
	transition: all 0.2s ease-out;
}

.btn a::before {
	content: "";
	position: absolute;
	height: 100%;
	top: 50%;
	width: 120%;
	left: -10%;
	transform: translateY(-50%);
	background-color: var(--red);
	transition: all 0.2s ease-out;
	border-radius: 50%;
	z-index: -1;
}

.btn a:hover {
	background-color: var(--blue);
}

.btn a:hover:before {
	height: 50%;
	width: 0%;
	left: 50%;
}

/* MOBILE */

@media (max-width: 900px) {
	.btn a {
		font-size: 16px;
		padding: 15px 30px;
	}
}
