.page-priceList {
	background: var(--orangeGradient);
}

section.priceList {
	width: calc(var(--widthContent) * 1.1);
	max-width: 100%;
	margin: 0 auto;
	padding: 50px 0 0 0;
}

section.priceList h2 {
	width: var(--widthContent);
	margin: 0 auto;
}

section.priceList h2 sup {
	font-size: 50px;
}

.priceList-wrapper {
	width: 100%;
	margin: 30px 0;
}

.priceList-box {
	display: flex;
	flex-direction: column;
	width: 350px;
	height: 490px;
	padding-bottom: 10px;
	margin: 20px auto 50px auto;
	border-radius: 10px;
	background-color: #eee;
	transition: all 0.1s ease-out;
}

.priceList-box:hover {
	transform: translateY(-20px);
	box-shadow: 1px 8px 5px rgba(0, 0, 0, 0.2);
}

.priceList-box h4 {
	padding: 20px 0;
	margin-bottom: 10px;
	font-size: 20px;
	text-align: center;
	font-weight: 500;
	letter-spacing: 1px;
	background-color: var(--red);
	color: white;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	text-shadow: 0 1px 1px #bbb;
}

.priceList-box h4.blue {
	background-color: var(--blue);
}

.priceList-block {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 20px;
	padding: 0 20px 10px;
	border-bottom: 1px dashed #ddd;
}

.priceList-block p {
	width: 80%;
	font-weight: 300;
	color: #333;
	font-size: 15px;
	text-align: justify;
}

.priceList-block span {
	display: block;
	width: 100%;
	text-align: right;
	text-shadow: 0 1px 1px #bbb;
	font-size: 14px;
}

.btn-priceList {
	align-self: center;
	margin: 0;
	margin-top: auto;
	width: 180px;
	border-radius: 50px;
}
.btn-priceList a {
	padding: 15px 30px;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 300;
}

/* DESCRIPTION */

.priceList-description {
	width: 100%;
	background: var(--greenGradient);
	padding: 50px 0;
}

.priceList-description ul {
	width: var(--widthContent);
	margin: 0 auto;
	list-style: none;
}

.priceList-description ul li {
	margin: 5px 0;
	color: white;
	font-size: 14px;
}

.priceList-description ul li a {
	color: white;
	text-decoration: none;
}
.priceList-description ul li a:hover {
	color: var(--red);
	text-shadow: 1px 1px 1px black;
}

/* SLIDER ELEMENTS */

/* PAGINATION BULLETS */
.swiper-pagination-bullet-active {
	background: var(--darkBlue);
}

/* NAVIGATION ARROWS */
.swiper-button-prev,
.swiper-button-next {
	color: var(--darkBlue);
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
	left: 0;
}
.swiper-button-next,
.swiper-rtl .swiper-button-prev {
	right: 0;
}

/* MEDIUM SCREEN */

@media (max-width: 1200px) {
	section.priceList {
		width: 80%;
	}

	section.priceList h2 {
		width: 80%;
	}

	.priceList-description ul {
		width: 80%;
	}
}

/* MOBILE SCREEN */

@media (max-width: 700px) {
	.priceList-box:hover {
		transform: translateY(0);
	}
}

@media (max-width: 700px) {
	.priceList-box {
		width: 330px;
		height: 460px;
	}
	.priceList-block p {
		font-size: 12px;
	}
	.priceList-block span {
		font-size: 11px;
	}
	.btn-priceList {
		width: 150px;
	}
	.btn-priceList a {
		padding: 10px 20px;
		font-size: 12px;
	}

	.swiper-button-prev::after,
	.swiper-button-next::after {
		font-size: 28px;
	}
}
