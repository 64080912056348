.footer {
	width: var(--widthContent);
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.footer::before,
.policy::before {
	content: "";
	background-size: 14px 100%;
	height: 16px;
	width: 100%;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 100%;
	background-image: linear-gradient(315deg, var(--blue) 25%, transparent 25%),
		linear-gradient(45deg, var(--blue) 25%, transparent 25%);
}

/* LOGO */

.footer-logo {
	height: 100%;
	width: 250px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.footer-logo img {
	width: 150px;
}

.phone-in-footer a {
	display: flex;
	text-decoration: none;
	color: white;
	font-style: italic;
	font-family: "Tangerine", cursive;
	transition: all 0.2s ease-out;
}

.phone-in-footer a:hover {
	color: var(--darkBlue);
}

.phone-in-footer a img {
	width: 26px;
}

.phone-in-footer a strong {
	margin-left: 10px;
	font-size: 50px;
	font-weight: 700;
}

/* CONTENT */

.footer-content {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: flex-end;
}

.socials a img {
	width: 50px;
	transition: transform 0.3s ease-out;
	cursor: pointer;
}

.socials a img:hover {
	transform: rotateY(-180deg);
}

.footer-nav {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.footer-nav-item {
	list-style: none;
}

.footer-nav-item a {
	color: white;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 900;
	letter-spacing: 1px;
	font-size: 22px;
	transition: all 0.2s ease-out;
}

.footer-nav-item a:hover {
	color: var(--darkBlue);
}

.footer-nav-item a.active {
	color: var(--darkBlue);
}

.policy {
	margin-top: 20px;
	background-color: var(--darkBlue);
	position: relative;
}

.policy::before {
	background-image: linear-gradient(
			315deg,
			var(--darkBlue) 25%,
			transparent 25%
		),
		linear-gradient(45deg, var(--darkBlue) 25%, transparent 25%);
}

.wrapper-policy {
	width: var(--widthContent);
	margin: 0 auto;
	display: flex;
	justify-content: flex-end;
}

.policy p {
	height: 100px;
	margin-top: 10px;
	font-size: 13px;
	font-weight: 300;
	color: white;
}
.policy p:nth-child(2) {
	margin-left: 5px;
}

.policy p a {
	color: white;
	text-decoration: none;
}

/* MEDIUM SCREEN */

@media (max-width: 1200px) {
	.footer {
		width: 80%;
	}

	.footer-content {
		width: 60%;
	}
	.wrapper-policy {
		width: 80%;
	}
}

/* MOBILE SCREEN */

@media (max-width: 900px) {
	.footer {
		flex-direction: column;
		align-items: center;
	}

	.footer-logo {
		margin-bottom: 20px;
	}

	.footer-content {
		align-items: center;
	}
	.socials {
		margin-bottom: 20px;
	}
	.footer-nav {
		flex-direction: column;
		align-items: center;
	}
	.footer-nav-item {
		margin: 5px 0;
	}
}
