.certificates {
	position: relative;
	background: var(--greenGradient);
}

.certificates-wrapper {
	width: var(--widthContent);
	margin: 0 auto;
	padding: 50px 0 100px 0;
}

.certificates-wrapper h2 {
	margin-bottom: 50px;
	text-align: center;
}

.certificates-gallery {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.certificate-box {
	width: 150px;
	margin: 10px 30px;
	cursor: pointer;
}

.certificate-box img {
	width: 100%;
	border-radius: 10px;
	transition: all 0.2s linear;
}

.certificate-box img:hover {
	transform: perspective(900px) rotateY(35deg);
}

/* MEDIUM SCREEN */

@media (max-width: 1200px) {
	.certificates-wrapper {
		width: 80%;
	}
}

/* MOBILE SCREEN */

@media (max-width: 900px) {
	.certificates-wrapper {
		width: 90%;
	}
	.certificate-box {
		margin: 10px 20px;
	}
}
@media (max-width: 700px) {
	.certificate-box {
		margin: 10px 10px;
		width: 130px;
	}
}
@media (max-width: 500px) {
	.certificate-box {
		/* width: 100px; */
	}
}
