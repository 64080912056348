.services {
	padding-top: 100px;
	padding-bottom: 50px;
	background: var(--redGradient);
	position: relative;
}

.wrapper-services {
	width: var(--widthContent);
	margin: 10px auto 50px auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	color: white;
}

.reverse {
	flex-direction: row-reverse;
}

/* CONTNENT SERVICES */

.content-services {
	width: 50%;
}

.content-services h2 {
	margin-bottom: 25px;
}

.content-services div {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.content-services div p {
	width: 100%;
	font-size: 18px;
}

/* OFFER LIST */

.content-services div ul {
	display: flex;
	flex-wrap: wrap;
	flex-grow: 1;
	margin: 5px 0 20px 25px;
	line-height: 150%;
}

.content-services div ul li {
	min-width: 30%;
	margin-right: 20px;
	list-style-image: url("../imgs/butterflyOpacity2_20x20.png");
	font-size: 18px;
	font-style: italic;
}

.content-services div .list li {
	width: 100%;
}

/* PHOTO */

.photos-services {
	width: 50%;
	display: flex;
	justify-content: center;
	align-self: center;
}

.photos-services img {
	display: block;
	width: 80%;
	box-shadow: -10px -10px 60px 2px black;
}

.photos-services .left-img {
	box-shadow: 10px 10px 60px 2px black;
}

.wrapper-services .text {
	width: 100%;
	font-size: 28px;
	letter-spacing: 2px;
	text-align: center;
}

.wrapper-services .text:nth-of-type(1) {
	margin-top: 90px;
}

@media (max-width: 1400px) {
	.content-services {
		width: 50%;
	}
}

@media (max-width: 1200px) {
	.wrapper-services {
		width: 80%;
	}
	.photos-services img {
		width: 90%;
	}

	.wrapper-services .text {
		font-size: 20px;
	}
	.wrapper-services .text:nth-of-type(1) {
		margin-top: 50px;
	}
}

@media (max-width: 900px) {
	.services {
		padding-top: 50px;
		padding-bottom: 30px;
	}
	.wrapper-services {
		flex-direction: column;
	}
	.content-services {
		width: 100%;
	}
	.content-services div p {
		font-size: 16px;
	}

	.content-services div ul li {
		font-size: 16px;
		margin-right: 0;
	}
	.photos-services {
		margin-top: 20px;
		width: 100%;
	}
}
