.wrapper-decorations {
	position: absolute;
	width: 100%;
	height: 100%;
}
.wrap-img {
	position: absolute;
}
.wrap-img img {
	display: block;
	width: 100%;
}

.starPink {
	width: 50px;
	top: 6%;
	left: 50%;
	transform: rotate(0);
	z-index: 5;
}

.starPink img {
	animation: rotate 15s linear infinite;
}

.butterflyOpacity {
	width: 150px;
	z-index: 5;
	top: 5%;
	left: 12%;
}

.flower {
	width: 130px;
	z-index: 5;
	bottom: 2%;
	right: 5%;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* MEDIUM SCREEN */

@media (max-width: 1200px) {
	.starPink {
		top: 60px;
		left: 60%;
	}

	.butterflyOpacity {
		top: 60px;
		left: 35%;
	}
}

/* MOBILE */

@media (max-width: 900px) {
	.starPink {
		width: 30px;
	}
	.butterflyOpacity {
		width: 100px;
	}
	.flower {
		width: 100px;
		bottom: 3%;
	}
}

@media (max-width: 700px) {
	.starPink {
		left: 80%;
	}
	.butterflyOpacity {
		left: 45%;
	}
	.flower {
		right: 1%;
		bottom: 1%;
	}
}

@media (max-width: 700px) {
	.starPink {
		left: 90%;
	}
	.butterflyOpacity {
		left: 55%;
	}
	.flower {
		right: 1%;
		bottom: 1%;
	}
}
