.page-animations {
	width: 100%;
	background: var(--redGradient);
	padding: 50px 0 100px 0;
	position: relative;
}

.animations {
	width: var(--widthContent);
	margin: 0 auto;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.animations h2 {
	width: 100%;
	margin-bottom: 50px;
	font-weight: 900;
	font-size: 100px;
	color: white;
	font-family: "Tangerine", cursive;
	text-shadow: var(--shadowText);
}

.animations p {
	margin-top: 30px;
	color: white;
	font-size: 14px;
	text-align: center;
	line-height: 150%;
}

.animations p span {
	display: block;
	font-size: 16px;
}

.content-animation-box {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	width: 360px;
	height: 500px;
	margin-bottom: 40px;
	padding: 20px;
	text-align: center;
	color: white;
	position: relative;
	z-index: 5;
}

.card {
	display: flex;
	flex-direction: column;
	z-index: 2;
	box-shadow: -20px -20px 0px 0px var(--redTransparent);

	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	padding: 20px;
	border-radius: 10px;
	clip-path: circle(5% at 90% 90%);
	transition: all 0.3s ease-in-out;
}

.card.card-show {
	clip-path: circle(100%);
	background-color: #fff;
}

@keyframes show {
	0% {
		clip-path: circle(5% at 90% 90%);
	}
	100% {
		clip-path: circle(100%);
	}
}
.animation-img-wrapper {
	width: 250px;
	height: 250px;
	margin: 0 auto;
	background-image: url("../imgs/the-sun.png");
	background-repeat: no-repeat;
	background-size: 250px 250px;
	transition: transform 0.5s linear;
}

.content-animation-box:hover .animation-img-wrapper {
	transform: rotate(90deg);
}

.content-animation-box:hover .animation-img-wrapper div img {
	transform: rotate(-90deg);
}

.animation-img-wrapper div {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s linear;
}

.animation-img-wrapper div img {
	width: 200px;
	height: 200px;
	border-radius: 50%;
	transition: transform 0.5s linear;
}

.card-heading {
	font-size: 28px;
	letter-spacing: 2px;
	font-weight: 300;
	text-shadow: 1px 1px 1px #000;
	transition: all 0.3s ease-in-out;
}

.card-heading-show {
	font-size: 30px;
	padding-bottom: 20px;
	color: #000;
	text-align: left;
	letter-spacing: 1px;
	font-weight: 900;
	text-shadow: none;
	border-bottom: 5px solid var(--redDark);
}

.content-animation-box .card-description {
	margin-top: 10px;
	line-height: 130%;
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: 300;
	font-style: italic;
}

.card .card-description {
	color: black;
	line-height: 1.5;
	text-align: justify;
}

.content-animation-box .btn-offer {
	display: block;
	margin: 0 auto;
	margin-top: auto;
	padding: 10px 20px;
	border-radius: 50px;
	font-size: 16px;
	font-weight: normal;

	background-color: var(--orange);
	color: white;
	transition: all 0.2s ease-out;
}

.content-animation-box .btn-offer:hover {
	background-color: var(--blue);
}

/* MEDIUM SCREEN */

@media (max-width: 1400px) {
	.animations {
		width: 80%;
	}
}

@media (max-width: 1200px) {
	.animations {
		width: 90%;
	}
}

@media (max-width: 900px) {
	.animations {
		width: 95%;
	}

	.animations h2 {
		font-size: 80px;
	}
}

@media (max-width: 800px) {
	.content-animation-box {
		width: 330px;
		height: 450px;
		margin-bottom: 20px;
		padding: 10px;
		margin-bottom: 30px;
	}
	.animation-img-wrapper {
		width: 200px;
		height: 200px;
		background-size: 200px 200px;
	}
	.animation-img-wrapper div img {
		width: 170px;
		height: 170px;
	}
	.card-heading {
		font-size: 22px;
	}
}

@media (max-width: 750px) {
	.content-animation-box {
		width: 360px;
		height: 410px;
	}
	.card .card-description {
		line-height: 1.3;
	}
}
