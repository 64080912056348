.wrapper-decorations {
	position: absolute;
	width: 100%;
	height: 100%;
}
.wrap-img {
	position: absolute;
}
.wrap-img img {
	display: block;
	width: 100%;
}

.smilingSun {
	width: 120px;
	top: -50px;
	left: 15%;
	z-index: 2;
}

.smilingCloud {
	width: 120px;
	top: 20px;
	right: 10%;
	z-index: 3;
	animation: fly3 30s linear infinite alternate;
}
.blueCloud2 {
	width: 120px;
	top: 60px;
	left: 5%;
	z-index: 4;
	animation: fly2 35s linear infinite alternate;
}
.blueCloud {
	width: 120px;
	top: -10px;
	left: 8%;
	z-index: 3;
	animation: fly 25s 1s linear infinite alternate;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes bounce {
	0% {
		transform: scale(1);
	}

	40% {
		transform: scale(1);
	}
	45% {
		transform: scale(1.2);
	}
	50% {
		transform: scale(0.9);
	}
	55% {
		transform: scale(1.1);
	}
	60% {
		transform: scale(1);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes fly {
	0% {
		transform: translateX(0);
	}

	20% {
		transform: translateX(60%);
	}

	40% {
		transform: translateX(120%);
	}

	60% {
		transform: translateX(180%);
	}

	80% {
		transform: translateX(240%);
		opacity: 1;
	}
	100% {
		transform: translateX(300%);
	}
}

@keyframes fly2 {
	0% {
		transform: translateX(0) translateX(0);
	}

	20% {
		transform: translateX(100%) translateY(20px);
	}

	40% {
		transform: translateX(200%) translateY(-30px);
	}

	60% {
		transform: translateX(300%) translateY(-50px);
	}

	80% {
		transform: translateX(400%) translateY(-60px);
		opacity: 1;
	}
	100% {
		transform: translateX(480%) translateY(-40px);
	}
}

@keyframes fly3 {
	0% {
		transform: translateX(0) translateX(0);
	}

	20% {
		transform: translateX(-50%) translateY(20px);
	}

	40% {
		transform: translateX(-100%) translateY(-20px);
	}

	60% {
		transform: translateX(-150%) translateY(-30px);
	}

	80% {
		transform: translateX(-200%) translateY(0);
	}
	100% {
		transform: translateX(-250%) translateY(20px);
	}
}
