.advantages {
	padding: 100px 0 50px 0;
	background: var(--orangeGradient);
	color: white;
	position: relative;
}

.content-advantages {
	width: var(--widthContent);
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.content-advantages h2 {
	text-align: center;
	z-index: 4;
}

.advantage-box {
	flex-basis: 25%;
	text-align: center;
}

.advantage-box img {
	display: block;
	width: 150px;
	height: 150px;
	margin: 20px auto;
}

.advantage-box h4 {
	margin-bottom: 10px;
	font-size: 26px;
	font-style: italic;
	letter-spacing: 2px;
	text-align: center;
	text-shadow: 1px 1px 1px #000;
}

.advantage-box p {
	width: 90%;
	margin: 0 auto;
	font-size: 16px;
	line-height: 150%;
}

/* MEDIUM SCREEN */

@media (max-width: 1200px) {
	.content-advantages {
		width: 80%;
	}
}
