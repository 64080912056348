.wrapper-decorations {
	position: absolute;
	width: 100%;
	height: 100%;
}
.wrap-img {
	position: absolute;
}
.wrap-img img {
	display: block;
	width: 100%;
}

.unicorns {
	width: 120px;
	top: 3%;
	right: 15%;
	z-index: 5;
	opacity: 0.8;
}

.redHeartInStar {
	width: 80px;
	top: 25%;
	left: 7%;
	z-index: 5;
	opacity: 0.7;
	animation: rotate 33s linear infinite;
}

.threeBalloons {
	width: 100px;
	bottom: 10%;
	right: 5%;
	z-index: 5;
	transform: translateY(0) translateX(0);
	animation: flyUp 13s linear infinite;
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}

	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes bounce {
	0% {
		transform: scale(1);
	}

	40% {
		transform: scale(1);
	}
	45% {
		transform: scale(1.2);
	}
	50% {
		transform: scale(0.9);
	}
	55% {
		transform: scale(1.1);
	}
	60% {
		transform: scale(1);
	}
	100% {
		transform: scale(1);
	}
}

@keyframes flyUp {
	0% {
		transform: translateY(0);
		opacity: 0;
	}
	10% {
		opacity: 1;
	}

	20% {
		transform: translateY(-60%) translateX(20px);
	}

	40% {
		transform: translateY(-120%) translateX(0);
	}

	60% {
		transform: translateY(-180%) translateX(-20px);
	}

	80% {
		transform: translateY(-240%) translateX(0);
		opacity: 1;
	}
	100% {
		transform: translateY(-300%) translateX(10px);
		opacity: 0;
	}
}

@media (max-width: 1400px) {
	.redHeartInStar {
		width: 70px;
		top: 20%;
		left: 3%;
	}
}

@media (max-width: 1200px) {
	.unicorns {
		width: 100px;
		top: 5%;
	}
	.threeBalloons {
		width: 90px;
		right: 2%;
		z-index: 5;
	}
}
@media (max-width: 1200px) {
	.unicorns {
		top: 2%;
	}
}

@media (max-width: 700px) {
	.redHeartInStar {
		width: 50px;
		top: 30%;
		left: 2%;
	}
}
